<template>
  <div class="all-info">
    <div class="all-info-menu" @click="go(item)" v-for="(item, index) in menus" :key="index">
      <i class="l-i iconfont" :class="item.icon"></i>
      <div class="menu-info">{{item.title}}</div>
      <!-- <circle-dom :size="26" :border-width="2" :rate="item.rate"></circle-dom> -->
      <i class="iconfont icon-jiantouyou"></i>
    </div>
  </div>
</template>

<script>
// import CircleDom from '@/views/person/info/circleDom'

let menus = [{
  icon: 'icon-Contacts',
  title: '基础信息',
  dataType: 'worker',
  rate: 0,
  routerName: 'wap.worker_base'
},
{
  icon: 'icon-Invoice',
  dataType: 'worker',
  title: '工作信息',
  rate: 0,
  routerName: 'wap.worker_work'
},
{
  icon: 'icon-Card',
  title: '身份证',
  rate: 0,
  routerName: 'wap.idcard'
},
{
  icon: 'icon-University',
  dataType: 'worker_edu',
  title: '教育经历',
  routerName: 'wap.edu',
  rate: 0
},
{
  icon: 'icon-Bag',
  dataType: 'his_work',
  title: '工作经历',
  routerName: 'wap.his_work',
  rate: 0
},
{
  icon: 'icon-Award',
  title: '奖励信息',
  dataType: 'rew',
  rate: 0,
  routerName: 'wap.rew',
},
{
  icon: 'icon-Book-mark',
  dataType: 'train',
  title: '培训与进修信息',
  rate: 0,
  routerName: 'wap.train',
},
{
  icon: 'icon-Document',
  dataType: 'worker_certificate_pro_qua',
  title: '专业技术资格证书',
  rate: 0,
  routerName: 'wap.certificate',
},
{
  icon: 'icon-Document',
  dataType: 'worker_certificate_doctor_pra',
  title: '执业证书',
  rate: 0,
  routerName: 'wap.certificate_doctor_pra',
},
{
  icon: 'icon-Document',
  dataType: 'worker_certificate_doctor_qua',
  title: '职业资格证书',
  rate: 0,
  routerName: 'wap.certificate_doctor_qua',
},
{
  icon: 'icon-Invoice',
  dataType: 'worker_job_title',
  title: '职称聘任记录',
  rate: 0,
  routerName: 'wap.job_title',
},
{
  icon: 'icon-Document',
  dataType: 'worker_certificate_pro_hire',
  title: '专业技术职务聘任证书',
  rate: 0,
  routerName: 'wap.certificate_pro_hire',
},
{
  icon: 'icon-Document',
  dataType: 'worker_certificate_other',
  title: '其他证书',
  rate: 0,
  routerName: 'wap.certificate_other',
}]

export default {
  components: {
    // CircleDom
  },
  data () {
    return {
      menus: menus
    }
  },
  computed: {
    workerId () {
      return (this.$store.getters.userPrevilege.find(v => v.objType === 'worker') || {objId: null}).objId
    }
  },
  methods: {
    go (menu) {
      this.$router.push({
        name: menu.routerName,
        query: {
          workerId: this.workerId
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.all-info {
  overflow-y: auto;
}
.all-info-menu {
  padding: 0 20px 0 30px;
  display: flex;
  align-items: center;
  height: 60px;
  background: #fff;
  font-size: 16px;
  font-weight: 500;
  .iconfont {
    font-size: 22px;
  }
  .icon-jiantouyou {
    font-size: 16px;
    margin-left: 25px;
    color: rgba(0,0,0,0.5);
  }
  .l-i {
    margin-right: 10px;
    margin-bottom: 3px;
  }
  .menu-info {
    font-size: 16px;
    color: #000;
  }
}
.menu-info {
  flex: 1;
}
</style>